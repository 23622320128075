import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = [
    'publisher', 
    'audioPublisher', 
    'screenPublisher', 
    'status', 
    'startPublishing', 
    'stopPublishing',
    'audioDeviceSelector' // Added new target for the audio device selector
  ];
  static values = { apiKey: String, sessionId: String, token: String, distributionUrl: String }

  connect() {
    this.subscribers = []
    this.session = OT.initSession(this.apiKeyValue, this.sessionIdValue);
    this.audioPublisher = null;
    this.screenSharePublisher = null;
    
    // Get available audio devices when controller connects
    this.loadAudioDevices();
  }
  
  loadAudioDevices() {
    // Set initial state
    this.audioDeviceSelectorTarget.innerHTML = '<option value="">Loading devices...</option>';
    
    // Get available devices using the OpenTok API
    OT.getDevices((err, devices) => {
      if (err) {
        this.handleError("Error getting audio devices", err);
        return;
      }
      
      // Clear the select box
      this.audioDeviceSelectorTarget.innerHTML = '';
      
      // Add the "None" option first
      const noneOption = document.createElement('option');
      noneOption.value = "none";
      noneOption.text = "Do not broadcast audio";
      this.audioDeviceSelectorTarget.add(noneOption);
      
      // Filter for just audio input devices
      const audioDevices = devices.filter(device => device.kind === 'audioInput');
      
      if (audioDevices.length === 0) {
        const option = document.createElement('option');
        option.value = "";
        option.text = "No microphones found";
        this.audioDeviceSelectorTarget.add(option);
      } else {
        // Add each audio device as an option
        audioDevices.forEach(device => {
          const option = document.createElement('option');
          option.value = device.deviceId;
          option.text = device.label || `Microphone ${audioDevices.indexOf(device) + 1}`;
          this.audioDeviceSelectorTarget.add(option);
        });
        
        // Select the first actual device by default
        if (audioDevices.length > 0) {
          this.audioDeviceSelectorTarget.selectedIndex = 1;
        }
      }
    });
  }
  
  // Method to handle audio device selection changes
  onAudioDeviceChange() {
    // If we're already publishing, stop and restart with the new device
    if (this.audioPublisher) {
      this.session.unpublish(this.audioPublisher);
      this.audioPublisher.destroy();
      this.audioPublisher = null;
      
      // Only republish if we're not selecting "none"
      if (this.audioDeviceSelectorTarget.value !== "none") {
        this.publishAudio();
      }
    }
  }
  
  publish(event) {
    // Hide receive broadcast container if present
    const receiveContainer = document.querySelector('[data-tag="receive_broadcast"]');
    if (receiveContainer) {
      receiveContainer.classList.add('hidden');
    }
    
    // First connect to the session
    this.session.connect(this.tokenValue, (error) => {
      if (error) {
        this.handleError("Error connecting to session", error);
        return;
      }
      
      // After successful connection, start publishing audio (if not set to "none")
      if (this.audioDeviceSelectorTarget.value !== "none") {
        this.publishAudio();
      }
      
      // Then check screen sharing capability
      this.publishScreen();
    });
  }
  
  publishAudio() {
    // Don't publish if "none" is selected
    if (this.audioDeviceSelectorTarget.value === "none") {
      console.log("Audio publishing disabled by user");
      return;
    }
    
    // Create audio-only publisher
    const publisherOptions = {
      insertMode: "append",
      width: "120px",
      height: "90px",
      videoSource: false,      // No video
      publishAudio: true,
      showControls: true,
      style: {
        buttonDisplayMode: 'on',
        nameDisplayMode: 'on',
      },
      name: 'Microphone'
    };
    
    // If a specific device is selected, set it with proper constraints format
    if (this.audioDeviceSelectorTarget.value && this.audioDeviceSelectorTarget.value !== "") {
      try {
        // Use constraints object format instead of direct device ID
        publisherOptions.audioSource = {
          deviceId: {
            exact: this.audioDeviceSelectorTarget.value
          }
        };
        console.log("Using audio device:", this.audioDeviceSelectorTarget.value);
      } catch (e) {
        console.error("Error setting audio device:", e);
        // Fallback to default device if there's an issue
        publisherOptions.audioSource = true;
      }
    } else {
      // Use default device
      publisherOptions.audioSource = true;
    }
    
    console.log("Audio publisher options:", publisherOptions);
    
    this.audioPublisher = OT.initPublisher(
      "audioPublisher",
      publisherOptions, 
      (error) => {
        if (error) {
          this.handleError("Error initializing audio publisher", error);
          // Fallback to default audio device
          if (error.name === "OverconstrainedError") {
            console.log("Falling back to default audio device");
            const defaultOptions = {...publisherOptions, audioSource: true};
            this.initializeWithDefaultAudio(defaultOptions);
          }
          return;
        }
        
        // Publish the audio stream to the session
        this.session.publish(this.audioPublisher, (error) => {
          if (error) {
            this.handleError("Error publishing audio", error);
            return;
          }
          console.log("Audio publishing started");
        });
      }
    );
  }

  // Add this helper method for fallback
  initializeWithDefaultAudio(options) {
    this.audioPublisher = OT.initPublisher(
      "audioPublisher",
      options,
      (error) => {
        if (error) {
          this.handleError("Error initializing with default audio", error);
          return;
        }
        
        this.session.publish(this.audioPublisher, (error) => {
          if (error) {
            this.handleError("Error publishing default audio", error);
            return;
          }
          console.log("Audio publishing started with default device");
        });
      }
    );
  }
  
  publishScreen() {
    OT.checkScreenSharingCapability(response => {
      if(!response.supported || response.extensionRegistered === false) {
        alert("Screen sharing not supported");
      } else if (response.extensionInstalled === false) {
        alert("Browser requires extension");
      } else {
        this.screenSharePublisher = OT.initPublisher(
          "publisher",
          {
            insertMode: "append",
            width: "100%",
            height: "100%",
            videoSource: "screen",
            audioSource: false,  // No audio from screen - we're using a separate audio stream
            publishAudio: false,
            name: 'Screen'
          }, 
          (error) => {
            if (error) {
              this.handleError("Error initializing screen publisher", error);
              return;
            }
            
            // Publish screen to the session
            this.session.publish(this.screenSharePublisher, this.handlePublishStarted.bind(this));
            
            // Listen for screen sharing stopped event
            this.screenSharePublisher.on('mediaStopped', this.stop.bind(this));
          }
        );
      }
    });
  }

  handleError(message, error) {
    console.error(message, error);
    alert(message + ": " + (error.message || error.name || "Unknown error"));
  }
  
  handlePublishStarted(_event) {
    const url = this.distributionUrlValue;
    console.log("Notifying server that broadcast started:", url);
    new FetchRequest('GET', url).perform();
    this.statusTarget.innerHTML = 'Broadcasting';
    this.startPublishingTarget.classList.add('hidden');
    this.stopPublishingTarget.classList.remove('hidden');
  }

  stop(_event) {
    // Unpublish and destroy screen publisher
    if (this.screenSharePublisher) {
      this.session.unpublish(this.screenSharePublisher);
      this.screenSharePublisher.destroy();
      this.screenSharePublisher = null;
    }
    
    // Unpublish and destroy audio publisher
    if (this.audioPublisher) {
      this.session.unpublish(this.audioPublisher);
      this.audioPublisher.destroy();
      this.audioPublisher = null;
    }
    
    // Disconnect from session
    this.session.disconnect();
    
    // Reset UI
    this.statusTarget.innerHTML = 'Not broadcasting';
    this.startPublishingTarget.classList.remove('hidden');
    this.stopPublishingTarget.classList.add('hidden');
    
    // Show receive broadcast container again
    const receiveContainer = document.querySelector('[data-tag="receive_broadcast"]');
    if (receiveContainer) {
      receiveContainer.innerHTML = '';
      receiveContainer.classList.remove('hidden');
    }
  }

  // Audio control methods for the audio publisher
  muteAudio() {
    if (this.audioPublisher) {
      this.audioPublisher.publishAudio(false);
    }
  }

  unmuteAudio() {
    if (this.audioPublisher) {
      this.audioPublisher.publishAudio(true);
    }
  }
  
  handleCallback(error) {
    if (error) {
      this.handleError("Callback error", error);
    }
  }
}



