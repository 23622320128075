import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['tabs', 'container', 'allCount', 'interestedCount', 'oneononeCount', 'invitedCount', 'candidate'];
  static values = { currentUserId: String, muted: Boolean, unreadCount: Number }
  activeClasses = ['bg-primary', 'text-white']
  inactiveClasses = ['bg-gray-200']

  connect() {
    // Options for the observer (which mutations to observe)
    const config = { attributes: false, childList: true, subtree: true };

    // when a presence is added, removed, or changes - update counts
    const callback = (mutationList, observer) => {
      for (const mutation of mutationList) {
        if (mutation.type === "childList") {
          this.updateCounts()
          this.updateFavorites()
        } else if (mutation.type === "attributes") {
          console.log(`The ${mutation.attributeName} attribute was modified.`);
        }
      }
    };

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);
    observer.observe(this.containerTarget, config);   
    this.updateCounts()
    this.updateFavorites()
  }    

  showAll(event){
    this.resetTabs()
    this.selectTab('all')
    this.containerTarget.querySelectorAll('.hidden').forEach(node => {
      node.classList.remove('hidden');
    });
  }

  showTab(event){
    this.resetTabs()
    const tab = event.params.tab
    this.selectTab(tab)
    this.containerTarget.querySelectorAll(`.presence`).forEach(node => {
      if (node.classList.contains(tab)) {        
        node.classList.remove('hidden');
      } else {
        node.classList.add('hidden');
      }
    });
  }

  resetTabs(){
    this.tabsTargets.forEach(tabs => {
      tabs.querySelectorAll('tab').forEach(node => {
        node.classList.remove(...this.activeClasses)
        node.classList.add(...this.inactiveClasses)
      })
    });
  }

  selectTab(tab){
    this.tabsTargets.forEach(tabs => {
      tabs.querySelector(`[data-candidate-presence-tab-param="${tab}"]`).classList.add(...this.activeClasses)
      tabs.querySelector(`[data-candidate-presence-tab-param="${tab}"]`).classList.remove(...this.inactiveClasses)
    });
  }

  updateCounts(){
    this.allCountTarget.innerHTML =  this.containerTarget.querySelectorAll(`.presence`).length
    this.interestedCountTarget.innerHTML =  this.containerTarget.querySelectorAll(`.interested`).length
    this.oneononeCountTarget.innerHTML =  this.containerTarget.querySelectorAll(`.oneonone`).length
    this.invitedCountTarget.innerHTML =  this.containerTarget.querySelectorAll(`.invited`).length
  }

  updateFavorites() {
    this.candidateTargets.forEach(candidate => {
      const byIds = JSON.parse(candidate.dataset.favoritedByIds)

      if (byIds.includes(this.currentUserIdValue)) {
        candidate.querySelector('#favorite').classList.remove('hidden')
      } else {
        candidate.querySelector('#favorite').classList.add('hidden')
      }
   })
  }

}