import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = ['tab', 'query', 'panel', 'online', 'rms'];
  static classes = ['active', 'blinking'];
  static values = { endPrivateUrl: String }

  connect() {
    this.boundHandleBlinkNotification = this.handleBlinkNotification.bind(this)
    document.addEventListener('blink-notification', this.boundHandleBlinkNotification)
    
    this.boundHandleTabClosed = this.handleTabClosed.bind(this)
    document.addEventListener('tab-closed', this.boundHandleTabClosed)
    
    // if there is a main room id in the URL, activate that room
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('main_room_id')) {
      const roomId = urlParams.get('main_room_id')
      this.activatePanel(roomId)      
    }
  }
  
  disconnect() {
    document.removeEventListener('blink-notification', this.boundHandleBlinkNotification)
    document.removeEventListener('tab-closed', this.boundHandleTabClosed)
  }
  

  // when the target is connected if the active panel has the same mainId as the tab, activate the tab
  tabTargetConnected(element) {
    const activePanel = this.panelTargets.find(panel => !panel.classList.contains('hidden'))
    if (activePanel) {
      this.activate_tab(activePanel.dataset.panelId)
    }    
  }

  toggleFilters(_event) {
    const onlineChecked = this.onlineTarget.checked
    const rmsChecked = this.rmsTarget.checked
    
    this.tabTargets.forEach((exhibitor) => {
      const isOnline = exhibitor.dataset.inRoom === "true"
      const isRms = exhibitor.dataset.rms === "true"
      let shouldShow = true
      
      // Apply online filter if checked
      if (onlineChecked) {
        shouldShow = shouldShow && isOnline
      }
      if (rmsChecked) {
        shouldShow = shouldShow && isRms
      }
      // Show or hide based on combined filter results
      if (shouldShow) {
        exhibitor.classList.remove('hidden')
      } else {
        exhibitor.classList.add('hidden')
      }
    })
    
    // Call search to apply any text filtering on top of checkbox filters
    if (!rmsChecked && !onlineChecked) {this.search()}
  }

  exitRoom(event) {
    const roomId = event.params.room
    const panel = this.roomPanel(roomId)
    const tab = this.roomTab(roomId)
    panel.remove()
    tab.classList.remove(...this.activeClasses)
  }

  handleBlinkNotification(event) {
    const { mainRoomId } = event.detail
    
    if (mainRoomId) {
      const tab = this.roomTab(mainRoomId)
      
      if (tab) {
        // Check if this tab is currently active
        const isActive = tab.classList.contains(this.activeClasses[0])
        
        // Only add blinking if this isn't the active tab
        if (!isActive) {
          tab.classList.add(...this.blinkingClasses)
        }
      }
    }
  }

  handleTabClosed(event) {
    const { meetingId, mainRoomId } = event.detail
    
    if (meetingId) {
      const panel = this.roomPanel(meetingId)
      const tab = this.roomTab(meetingId)
      
      if (panel && tab) {
        const isActive = tab.classList.contains(this.activeClasses[0])
        const otherTab = this.tabTargets.find(t => t !== tab)
        
        panel.remove()
        tab.remove()
        
        if (isActive && otherTab) {
          this.activatePanel(otherTab.dataset.tabId)
        }
      }
      
      if (this.hasEndPrivateUrlValue) {
        const url = this.endPrivateUrlValue.replace('meetingId', meetingId).replace('mainRoomId', mainRoomId)
        const request = new FetchRequest('GET', url, { responseKind: 'turbo-stream' })
        request.perform().catch(error => {
          console.error('Error ending private meeting:', error)
        })
      }
    }
  }

  //whenever a panel is connected, we want to make sure that the tab is selected
  panelTargetConnected(element) {
    const roomId = element.dataset.panelId
    this.activatePanel(roomId)
  }

  search() {
    this.tabTargets.forEach((exhibitor) => {
      if (exhibitor.innerText.toLowerCase().includes(this.queryTarget.value.toLowerCase())) {
        exhibitor.classList.remove('hidden')
      } else {
        exhibitor.classList.add('hidden')
      }
    })
  }

  async activateRoom(event) {
    const roomId = event.params.room
    this.activatePanel(roomId)
  }

  async activatePanel(roomId) {
    const element = this.roomTab(roomId).querySelector('[data-event-control-center-url-param]')
    const url = element.dataset.eventControlCenterUrlParam
    const panel = this.roomPanel(roomId)
    if (!panel) { await Turbo.visit(url) }
    this.hideAll()
    this.activate_tab(roomId)
  }

  roomTab(id) {
    const tab = this.tabTargets.find(tab => tab.dataset.tabId == id)
    return tab
  }

  roomPanel(id) {
    const panel = document.querySelector( `[data-panel-id='${id}']`)
    return panel
  }

  activate_tab(id) {
    const tab = this.roomTab(id)
    const panel = this.roomPanel(id)
    tab.classList.add(...this.activeClasses)
    tab.classList.remove(this.blinkingClasses)
    // if the panel was just loaded form the server, this may be null, but that is okay because new panels are already visible
    if (panel != null) {panel.classList.remove('hidden')}
  }

  hideAll() {
    this.panelTargets.forEach((exhibitor) => {exhibitor.classList.add('hidden')})
    this.tabTargets.forEach((exhibitor) => {exhibitor.classList.remove('bg-primary', 'text-white')})
  }

}