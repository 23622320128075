import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = ['tab', 'panel', 'privateTab', 'noChatMessage', 'privateChatContainer']
  static values = { eventId: String, mainRoomId: String, endPrivateUrl: String, ratingUrl: String, candidatePaneUrl: String }
  static classes = [ "active", "blinking" ]

  connect() {
    this.boundHandleFocusTab = this.handleFocusTab.bind(this)
    document.addEventListener('focus-tab', this.boundHandleFocusTab)

    this.boundHandleTabFocused = this.handleTabFocused.bind(this)
    document.addEventListener('tab-focused', this.boundHandleTabFocused)

    this.boundHandleBlinkNotification = this.handleBlinkNotification.bind(this)
    document.addEventListener('blink-notification', this.boundHandleBlinkNotification)
 
    this.boundHandleTabClosed = this.handleTabClosed.bind(this)
    document.addEventListener('tab-closed', this.boundHandleTabClosed)
   
    this.boundHandleTabCountChanged = this.handleTabCountChanged.bind(this)
    document.addEventListener('tab-count-changed', this.boundHandleTabCountChanged) 
  } 

  disconnect() {
    document.removeEventListener('focus-tab', this.boundHandleFocusTab)
    document.removeEventListener('blink-notification', this.boundHandleBlinkNotification)
    document.removeEventListener('tab-closed', this.boundHandleTabClosed)
  }

  handleFocusTab(event) {
    const { meetingId, otherParticipantUserId, otherParticipantRole } = event.detail    
    if (meetingId && this.mainRoomIdValue && meetingId !== this.mainRoomIdValue) {
      Alpine.evaluate(this.element, 'chat = "private"')
    }
  }

  handleTabFocused(event) {
    const { meetingId, otherParticipantUserId, otherParticipantRole } = event.detail    
    if (otherParticipantRole === 'candidate') {
      document.getElementById('candidate_details').src = this.candidatePaneUrlValue.replace('candidateId', otherParticipantUserId)
    }  
  }
  
  handleTabCountChanged(event) {
    const { tabCount } = event.detail
    if (tabCount === 0) {
      this.noChatMessageTarget.classList.remove('hidden')
      this.privateChatContainerTarget.classList.add('hidden')
    } else {
      this.noChatMessageTarget.classList.add('hidden')
      this.privateChatContainerTarget.classList.remove('hidden')
    }
  }

  handleBlinkNotification(event) {
    const { meetingId } = event.detail

    if (meetingId && this.mainRoomIdValue && meetingId !== this.mainRoomIdValue) {
      const isPrivateTabActive = Alpine.evaluate(this.element, 'chat === "private"')
      
      if (!isPrivateTabActive) {
        this.privateTabTarget.classList.add(...this.blinkingClasses)
      }
    }
  }
  
  handleTabClosed(event) {
    const { meetingId, otherParticipantUserId, otherParticipantRole } = event.detail
    
    if (meetingId) {
      const url = this.endPrivateUrlValue.replace('meetingId', meetingId)
      
      const request = new FetchRequest('GET', url, { responseKind: 'turbo-stream' })
      request.perform()
        .catch(error => {
          console.error('Error ending private meeting:', error)
        })
      
      if (otherParticipantRole === 'candidate') {
        document.getElementById('client_event_modal').src = `${this.ratingUrlValue}?candidate_id=${otherParticipantUserId}`
      }
    }
  }

  tabClicked(event) {
    const panel = document.getElementById('private_room_panel')
    const tab = panel.querySelector('.animate-blink-primary') 
    if (tab) {
      tab.click()
    }
    event.target.classList.remove(...this.blinkingClasses)
  }
}