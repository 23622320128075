import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['videoContainer', 'subscriber', 'publisher', 'screenPublisher', 'share', 'cancelShare'];
  static values = { apiKey: String, sessionId: String, token: String }
 

  connect() {
    this.subscribers = []
    this.session = OT.initSession(this.apiKeyValue, this.sessionIdValue);
  
    // Subscribe to a newly created stream
    var subscriberTarget = this.subscriberTarget
    this.session.on('streamCreated', this.streamCreated.bind(this) );

        
    // we want to add a class to the subscriber element when the stream is destroyed
    this.session.on('streamDestroyed', function(event) {
      const vids = [...subscriberTarget.children]
      vids.forEach((element) => {
        element.classList.remove('hidden'); //we are doing this becuase we hide the video element when screeen sharing is happening. 
      })
    });

    this.start()
  }

  streamCreated(event) {  
    var subscriber = this.session.subscribe(event.stream, 'subscriber', {
      insertMode: 'append',
      width: '100%',
      height: '100%',
    }, this.handleCallback.bind(this))

    this.subscribers.push(subscriber)    
    this.cleanupExtraVideos(this.subscriberTarget)
    this.setVisibility(subscriber.id)
  }

  cleanupExtraVideos(subscriberTarget) {
    const subscriberIds = this.subscribers.map((subscriber) => subscriber.id)
    const vids = [...this.subscriberTarget.children]

    //remove any vids whose ids are not in the subscriberIds array
    vids.forEach((element) => {
      if (!subscriberIds.includes(element.id)) {
        subscriberTarget.removeChild(element)
      }
    })
  }

  setVisibility(id) {
    const vids = [...this.subscriberTarget.children]
  
    vids.find((element) => {
      if (element.id === id) {
        element.classList.remove('hidden');
      }
    })

    // hide the rest
    vids.forEach((element) => {
      if (element.id !== id) {
        element.classList.add('hidden');
      }
    })
  }


  initiateCall() {
    // Create a publisher    
    this.publisher = OT.initPublisher('publisher', {
      insertMode: 'append',
      width: '100%',
      height: '100%',
      style: {buttonDisplayMode: 'off'}
    }, this.handleCallback.bind(this));

    // Connect to the session
    this.session.connect(this.tokenValue, this.publisherStreamConnected.bind(this))
  }

  publisherStreamConnected(error) {
    if (error) {
      
    } else {
      this.session.publish(this.publisher, this.handleCallback.bind(this))
    }
  }

  start() {
    this.videoContainerTarget.classList.remove('hidden')
    this.initiateCall()
  }

  end(event) {    
    this.videoContainerTarget.classList.add('hidden')
    this.subscriberTarget.innerHTML = ''
    this.publisherTarget.innerHTML = ''
    this.publisher.destroy()
    for (var i = 0; i < this.subscribers.length; i++) {
      var subscriber = this.subscribers[i]      
      this.session.unsubscribe(subscriber)
    }
    this.session.disconnect()    
    this.subscribers = []    
  }

  shareScreen(event) {
    this.screenSharePublisher = null

    OT.checkScreenSharingCapability(response => {
      if(!response.supported || response.extensionRegistered === false) {
        alert("Screen sharing not supported")
      } else if (response.extensionInstalled === false) {
        alert("Browser requires extension")
      } else {
        this.screenSharePublisher = OT.initPublisher(
          "screen-publisher",
          {
            insertMode: "append",
            width: "100%",
            height: "100%",
            videoSource: "screen",
            publishAudio: true
          }, this.handleScreenPublished.bind(this)            
        )        
      }
    })    
  }

  handleScreenPublished(error){
    if (!error) {
      this.session.publish(this.screenSharePublisher, this.handleCallback.bind(this))
    }    
    this.screenSharePublisher.on('mediaStopped', this.cancelShare.bind(this))
    this.shareTarget.classList.add('hidden')
    this.cancelShareTarget.classList.remove('hidden')
  }

  cancelShare(_event) {
    this.session.unpublish(this.screenSharePublisher)
    this.screenSharePublisher.destroy()
    this.shareTarget.classList.remove('hidden')
    this.cancelShareTarget.classList.add('hidden')
  }

  blur(event) {
    this.publisher.applyVideoFilter({
      type: 'backgroundBlur',
      blurStrength: 'high'  // You can set the blur strength to 'low', 'medium', or 'high'
    });
  }

  unblur(event) {
    this.publisher.clearVideoFilter();
  }

  mute(event) {
    this.publisher.publishAudio(false);
  }

  unmute(event) {
    this.publisher.publishAudio(true);
  }

  videoOn(event) {
    this.publisher.publishVideo(true);
  }

  videoOff(event) {
    this.publisher.publishVideo(false);
  }

  muteIncoming(event) {
    this.subscriber.subscribeToAudio(false);
  }

  unmuteIncoming(event) {
    this.subscriber.subscribeToAudio(true);
  }
  
  handleCallback(error) {
    if (error) {
      alert(error.message);
    }
  }
}