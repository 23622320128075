import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static targets = [ "hiddenId", "controller", "input", "dropzone", "preview", "previews", "uploading"]
  static values = {
  }

  connect() {
  }

  dragover() {
    this.highlight()
  }

  dragleave() {
    this.lowlight() 
  }

  drop(event) {
    this.lowlight()
    this.file_attached(event)
  }

  change(event) {
    this.file_attached(event)
  }

  remove(event) {
    const preview = event.target.closest("[data-file-upload-target=preview]")
    const checkbox = preview.querySelector("input[type=checkbox]")
    if(checkbox) {
      this.lowlight()
      preview.classList.add("hidden")
      this.dropzoneTarget.closest("div").classList.remove("hidden")
      checkbox.checked = true
    } else {
      console.error("Could not find checkbox to mark file for deletion")
    }
  }

  async file_attached(event) {

    this.dropzoneTarget.classList.add("hidden")

    const files = this.inputTarget.files
    for(let i = 0; i < files.length; i++) {
      const file = files[i]
      const preview = this.previewTarget.cloneNode(true)
      preview.classList.remove("hidden")
      preview.querySelector(".preview-filename").textContent = `Uploading ${file.name}`
      this.previewsTarget.appendChild(preview)
    }

    this.submitForm()
  }

  async submitForm(){
    const form = this.controllerTarget.closest('form')
    const formData = new FormData(form)

    const response = await fetch(form.action, {
      method: "PATCH",
      body: formData,
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
      }
    })
    Turbo.renderStreamMessage(await response.text());
  }

  highlight() {
    this.dropzoneTarget.classList.remove("bg-gray-50")
    this.dropzoneTarget.classList.remove("hover:bg-gray-100")
    this.dropzoneTarget.classList.add("bg-blue-200")
    this.dropzoneTarget.classList.add("text-black")
    this.dropzoneTarget.classList.add("border-primary-2")
  }

  lowlight() {
    this.dropzoneTarget.classList.add("bg-gray-50")
    this.dropzoneTarget.classList.add("hover:bg-gray-100")
    this.dropzoneTarget.classList.remove("bg-blue-200")
    this.dropzoneTarget.classList.remove("text-black")
    this.dropzoneTarget.classList.remove("border-primary-2")
  }
}
