import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static targets = ["input", "results"]

  search() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      const baseUrl = window.location.origin
      const path = this.inputTarget.dataset.url
      const url = new URL(path, baseUrl)
      url.searchParams.set('q', this.inputTarget.value)
      
      fetch(url.toString())
        .then(response => response.text())
        .then(html => {
          const parser = new DOMParser();
          const doc = parser.parseFromString(html, 'text/html');
          const newResults = doc.querySelector('[data-featured-jobs-search-target="results"]');
          if (newResults) {
            // Store currently checked boxes
            const currentChecked = Array.from(this.resultsTarget.querySelectorAll('input[type="checkbox"]:checked'))
              .map(cb => cb.value);
            
            // Update the results content
            this.resultsTarget.innerHTML = newResults.innerHTML;
            
            // Restore checked state
            currentChecked.forEach(value => {
              const checkbox = this.resultsTarget.querySelector(`input[type="checkbox"][value="${value}"]`);
              if (checkbox) checkbox.checked = true;
            });
          }
        })
        .catch(error => console.error('Search error:', error))
    }, 300)
  }
}
