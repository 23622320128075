import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    minCharacters: Number,
    delay: Number
  }

  connect() {
    this.timeout = null
  }

  submit(event) {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    const query = event.target.value

    if (query.length >= this.minCharactersValue) {
      this.timeout = setTimeout(() => {
        this.element.requestSubmit()
      }, this.delayValue)
    }
  }
}

