import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = ['tabContainer', 'panelContainer', 'tab', 'panel', 'loadingIndicator']
  static values = { mainRoomId: String }
  static classes = [ "active", "blinking" ]

  tabCount = 0

  connect() {      
    this.boundHandleBlinkNotification = this.handleBlinkNotification.bind(this)
    document.addEventListener('blink-notification', this.boundHandleBlinkNotification)
    
    this.boundHandleCloseMeeting = this.handleCloseMeeting.bind(this)
    document.addEventListener('close-meeting-notification', this.boundHandleCloseMeeting)

    //this runs after tab targets are connected, do if it is 0 make sure we aren't showing the loading indicator
    if (this.tabCount == 0) this.loadingIndicatorTarget.classList.add('hidden')
  }
  
  disconnect() {
    document.removeEventListener('blink-notification', this.boundHandleBlinkNotification)
    document.removeEventListener('close-meeting-notification', this.boundHandleCloseMeeting)
  }
  

  handleBlinkNotification(event) {
    const { meetingId } = event.detail

    if (meetingId) {
      const tab = this.#tabForMeeting(meetingId)
      if (tab) {
        const activeTab = this.tabTargets.find(t => t.classList.contains(this.activeClasses[0]))
        
        if (!activeTab || activeTab.dataset.meetingId !== meetingId) {
          tab.classList.add(...this.blinkingClasses)
        }
      }
    }
  }
  
  handleCloseMeeting(event) {
    const { meeting_id } = event.detail
    
    if (meeting_id) {
      const tab = this.#tabForMeeting(meeting_id)
      const panel = this.#panelForMeeting(meeting_id)
      
      if (tab && panel) {
        const isActiveTab = tab.classList.contains(this.activeClasses[0])
        const otherTab = tab.nextElementSibling || tab.previousElementSibling
        
        panel.remove()
        tab.remove()
        

        if (isActiveTab && otherTab) {
          this.activateTab(otherTab.dataset.meetingId)
        }
      }
    }
  }
  
  tabTargetConnected(element) {
    this.fetchPanel(element)
    this.updateTabCount()
  }

  panelTargetConnected(element) {
    const tab = this.tabTargets.find(tab => tab.dataset.meetingId == element.dataset.meetingId)
    if (this.panelTargets.length === this.tabTargets.length) {
      this.loadingIndicatorTarget.classList.add('hidden')
      if (tab.dataset.focus === "true") {
        this.activateTab(tab.dataset.meetingId)
        this.dispatchFocusTabEvent(element.dataset.meetingId, element.dataset.mainRoomId)
      } else {
        this.selectInitialTab()
      }
    }
  }

  fetchPanel(node) {
    const meetingId = node.dataset.meetingId
    if (this.#panelForMeeting(meetingId)) { return } // if the panel is already here, nope out

    const participantId = node.dataset.participantId
    const url = `/meetings/${meetingId}/chat_panel/${participantId}`
  
    const request = new FetchRequest('GET', url, { responseKind: 'turbo-stream' })
    request.perform()
  }

  activateTab(meetingId) {
    this.hideAll()
    const tab = this.tabTargets.find(tab => tab.dataset.meetingId == meetingId)
    const panel = this.panelTargets.find(panel => panel.dataset.meetingId == meetingId)

    tab.classList.add(...this.activeClasses)
    tab.classList.remove(this.blinkingClasses)
    panel.classList.remove('hidden')
    this.dispatchTabFocusedEvent(meetingId, this.mainRoomIdValue) 
  } 

  handleTabClick(event) {
    const meetingId = event.target.dataset.meetingId
    this.activateTab(meetingId)
  }

  hideAll() {
    this.panelTargets.forEach((panel) => {panel.classList.add('hidden')})
    this.tabTargets.forEach((tab) => {tab.classList.remove(...this.activeClasses)})
  }

  updateTabCount() {
    this.tabCount = this.tabTargets.length
    this.dispatch('tab-count-changed', {detail: {tabCount: this.tabCount, mainRoomId: this.mainRoomIdValue}, bubbles: true, prefix: false})
  }


  dispatchFocusTabEvent(meetingId, mainRoomId) {
    const tab = this.#tabForMeeting(meetingId)
    const otherParticipantUserId = tab.dataset.otherParticipantUserId
    const otherParticipantRole = tab.dataset.otherParticipantRole
    this.dispatch('focus-tab', {
      detail: {meetingId: meetingId, mainRoomId: mainRoomId, otherParticipantUserId: otherParticipantUserId, otherParticipantRole: otherParticipantRole}, 
      bubbles: true, 
      prefix: false 
    })
  }

  dispatchTabFocusedEvent(meetingId, mainRoomId) {
    const tab = this.#tabForMeeting(meetingId)
    const otherParticipantUserId = tab.dataset.otherParticipantUserId
    const otherParticipantRole = tab.dataset.otherParticipantRole
    this.dispatch('tab-focused', {detail: {meetingId: meetingId, mainRoomId: mainRoomId, otherParticipantUserId: otherParticipantUserId, otherParticipantRole: otherParticipantRole}, bubbles: true, prefix: false })
  }

  selectInitialTab() {
    this.loadingIndicatorTarget.classList.add('hidden')
    const tab = this.tabTargets.find(tab => tab.dataset.focus === "true") || this.#tabForUrlParam()
    if (tab) {
      this.dispatchFocusTabEvent(tab.dataset.meetingId, tab.dataset.mainRoomId)
      this.activateTab(tab.dataset.meetingId)
    } else {
      this.activateTab(this.tabTargets[0].dataset.meetingId)
    }
  }

  endChat(event){
    const tab = event.target.closest('tab')
    if (!tab) return
    
    const meetingId = tab.dataset.meetingId
    const otherParticipantUserId = tab.dataset.otherParticipantUserId
    const otherParticipantRole = tab.dataset.otherParticipantRole
    const panel = this.#panelForMeeting(meetingId)
    const otherTab = tab.nextElementSibling || tab.previousElementSibling
      
    this.dispatch('tab-closed', {
      detail: {
        meetingId,
        mainRoomId: this.mainRoomIdValue,
        otherParticipantUserId,
        otherParticipantRole
      }, 
      bubbles: true, 
      prefix: false 
    })
    
    if (panel) panel.remove()
    tab.remove() // No need for condition here since we checked above
    this.updateTabCount()
    if (otherTab) {this.activateTab(otherTab.dataset.meetingId)}
    event.stopPropagation()
  }

  #tabForUrlParam() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('chat_id')) {
      const chatId = urlParams.get('chat_id')
      return this.#tabForMeeting(chatId)
    }
  }

  #tabForMeeting(meetingId) {
    return this.tabTargets.find(tab => tab.dataset.meetingId == meetingId)
  }
  #panelForMeeting(meetingId) {
    return this.panelTargets.find(panel => panel.dataset.meetingId == meetingId)
  }

}
