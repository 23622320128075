import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'status', 'videoSource', 'audioSource', 
    'videoContainer', 'audioMeter',
    'startTest', 'stopTest', 'toggleMute', 'toggleVideo',
    'successMessage', 'errorMessage', 'errorText'
  ]
  
  static values = { 
    apiKey: String, 
    sessionId: String, 
    token: String,
    callbackPath: String
  }

  connect() {
    this.devices = []
    this.publisher = null
    this.audioLevel = 0
    this.audioAnalyzer = null
    this.audioContext = null
    this.audioSource = null
    this.audioMuted = false
    this.videoEnabled = true
    this.mediaStream = null
    
    this.session = OT.initSession(this.apiKeyValue, this.sessionIdValue)
    
    this.statusTarget.textContent = 'Detecting available devices...'
    
    this.getDevices()
  }
  
  async getDevices() {
    try {
      const devices = await new Promise((resolve, reject) => {
        OT.getDevices((err, deviceList) => {
          if (err) {
            this.handleError('getDevices error', err)
            reject(err)
            return
          }
          resolve(deviceList)
        })
      })
      
      this.devices = devices
      
      const videoDevices = this.devices.filter(device => device.kind === 'videoInput')
      this.videoSourceTarget.innerHTML = ''
      
      if (videoDevices.length === 0) {
        this.addOption(this.videoSourceTarget, '', 'No cameras found')
      } else {
        videoDevices.forEach(device => {
          this.addOption(this.videoSourceTarget, device.deviceId, device.label || `Camera ${videoDevices.indexOf(device) + 1}`)
        })
      }
    
      const audioDevices = this.devices.filter(device => device.kind === 'audioInput')
      this.audioSourceTarget.innerHTML = ''
      
      if (audioDevices.length === 0) {
        this.addOption(this.audioSourceTarget, '', 'No microphones found')
      } else {
        audioDevices.forEach(device => {
          this.addOption(this.audioSourceTarget, device.deviceId, device.label || `Microphone ${audioDevices.indexOf(device) + 1}`)
        })
      }
      
      this.statusTarget.textContent = 'Devices detected. Click "Start Test" to begin.'
      
    } catch (error) {
      this.handleError('Could not access devices', error)
    }
  }


  addOption(selectElement, value, text) {
    const option = document.createElement('option')
    option.value = value
    option.text = text
    selectElement.add(option)
  }
  
  startDeviceTest() {
    const videoSourceId = this.videoSourceTarget.value
    const audioSourceId = this.audioSourceTarget.value
    
    this.statusTarget.textContent = 'Initializing devices...'
    
    const publisherOptions = {
      insertMode: 'append',
      width: '100%',
      height: '100%',
      showControls: false
    }
    
    if (videoSourceId && videoSourceId !== '') {
      publisherOptions.videoSource = true
    }
    
    if (audioSourceId && audioSourceId !== '') {
      publisherOptions.audioSource = true
    }
    
    try {
      if (this.publisher) {
        this.publisher.destroy()
        this.publisher = null
      }
      
      this.videoContainerTarget.innerHTML = ''
      
      this.publisher = OT.initPublisher(
        this.videoContainerTarget,
        publisherOptions,
        (error) => {
          if (error) {
            if (error.name === 'OT_USER_MEDIA_ACCESS_DENIED' || error.name === 'OverconstrainedError') {
              this.tryWithDefaultDevices()
              return
            }
            this.handleError('Failed to initialize devices', error)
            return
          }
          
          this.session.connect(this.tokenValue, (error) => {
            if (error) {
              console.warn("Session connection error, continuing with preview")
            }
            
            this.statusTarget.textContent = 'Devices working! Testing audio and video...'
            this.setupAudioLevelMonitor()
            this.showTestControls()
            this.successMessageTarget.classList.remove('hidden')
          })
        }
      )
      
    } catch (error) {
      this.handleError('Error starting device test', error)
    }
  }
  
  tryWithDefaultDevices() {
    this.statusTarget.textContent = 'Using default devices instead...'
    
    const defaultOptions = {
      insertMode: 'append',
      width: '100%',
      height: '100%',
      showControls: false,
      videoSource: true,
      audioSource: true
    }
    
    this.publisher = OT.initPublisher(
      this.videoContainerTarget,
      defaultOptions,
      (error) => {
        if (error) {
          this.handleError('Failed to initialize default devices', error)
          return
        }
        
        this.session.connect(this.tokenValue, (error) => {
          if (error) {
            console.warn("Session connection error, continuing with preview")
          }
          
          this.statusTarget.textContent = 'Using default devices. Testing audio and video...'
          this.setupAudioLevelMonitor()
          this.showTestControls()
          this.successMessageTarget.classList.remove('hidden')
        })
      }
    )
  }
  
  setupAudioLevelMonitor() {
    if (!this.publisher || !this.publisher.getAudioSource) {
      return
    }
    
    try {
      if (!this.audioContext) {
        this.audioContext = new (window.AudioContext || window.webkitAudioContext)()
      }
      
      const audioSource = this.publisher.getAudioSource()
      
      if (!audioSource) {
        return
      }
      
      const analyser = this.audioContext.createAnalyser()
      analyser.fftSize = 256
      const bufferLength = analyser.frequencyBinCount
      const dataArray = new Uint8Array(bufferLength)
      
      const source = this.audioContext.createMediaStreamSource(new MediaStream([audioSource]))
      source.connect(analyser)
      
      this.audioAnalyzer = analyser
      this.audioSource = source
      
      this.audioLevelInterval = setInterval(() => {
        analyser.getByteFrequencyData(dataArray)
        
        let sum = 0
        for (let i = 0; i < bufferLength; i++) {
          sum += dataArray[i]
        }
        const average = sum / bufferLength
        const level = Math.min(100, Math.round((average / 128) * 100))
        
        this.audioMeterTarget.style.width = `${level}%`
      }, 100)
      
    } catch (error) {
      console.error("Error setting up audio monitor:", error)
    }
  }
  
  stopDeviceTest() {
    if (this.publisher) {
      this.publisher.destroy()
      this.publisher = null
    }
    
    if (this.audioLevelInterval) {
      clearInterval(this.audioLevelInterval)
    }
    
    if (this.audioSource) {
      this.audioSource.disconnect()
      this.audioSource = null
    }
    
    this.hideTestControls()
    this.successMessageTarget.classList.add('hidden')
    this.statusTarget.textContent = 'Test stopped. Click "Start Test" to try again.'
  }
  
  changeVideoDevice() {
    if (this.publisher) {
      this.stopDeviceTest()
      this.startDeviceTest()
    }
  }
  
  changeAudioDevice() {
    if (this.publisher) {
      this.stopDeviceTest()
      this.startDeviceTest()
    }
  }
  
  toggleMute() {
    if (!this.publisher) return
    
    this.audioMuted = !this.audioMuted
    this.publisher.publishAudio(!this.audioMuted)
    
    this.toggleMuteTarget.textContent = this.audioMuted ? 'Unmute' : 'Mute'
    this.toggleMuteTarget.classList.toggle('bg-blue-500', !this.audioMuted)
    this.toggleMuteTarget.classList.toggle('bg-red-500', this.audioMuted)
  }
  
  toggleVideo() {
    if (!this.publisher) return
    
    this.videoEnabled = !this.videoEnabled
    this.publisher.publishVideo(this.videoEnabled)
    
    this.toggleVideoTarget.textContent = this.videoEnabled ? 'Turn Off Camera' : 'Turn On Camera'
    this.toggleVideoTarget.classList.toggle('bg-blue-500', this.videoEnabled)
    this.toggleVideoTarget.classList.toggle('bg-red-500', !this.videoEnabled)
  }
  
  showTestControls() {
    this.startTestTarget.classList.add('hidden')
    this.stopTestTarget.classList.remove('hidden')
    this.toggleMuteTarget.classList.remove('hidden')
    this.toggleVideoTarget.classList.remove('hidden')
  }
  
  hideTestControls() {
    this.startTestTarget.classList.remove('hidden')
    this.stopTestTarget.classList.add('hidden')
    this.toggleMuteTarget.classList.add('hidden')
    this.toggleVideoTarget.classList.add('hidden')
  }
  
  continueToMeeting() {
    if (this.videoSourceTarget.value) {
      localStorage.setItem('preferredVideoDevice', this.videoSourceTarget.value)
    }
    
    if (this.audioSourceTarget.value) {
      localStorage.setItem('preferredAudioDevice', this.audioSourceTarget.value)
    }
    
    this.stopDeviceTest()
    
    if (this.callbackPathValue) {
      window.location.href = this.callbackPathValue
    }
  }
  
  handleError(message, error) {
    console.error(message, error)
    this.statusTarget.textContent = 'Error testing devices'
    this.errorTextTarget.textContent = error ? error.message || error.toString() : message
    this.errorMessageTarget.classList.remove('hidden')
    this.hideTestControls()
  }
  
  disconnect() {
    if (this.publisher) {
      this.publisher.destroy()
    }
    
    if (this.audioLevelInterval) {
      clearInterval(this.audioLevelInterval)
    }
    
    if (this.session) {
      this.session.disconnect()
    }
    
    if (this.audioContext) {
      this.audioContext.close()
    }
  }
}