import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  static targets = ['subscriber'];
  static values = { apiKey: String, sessionId: String, token: String }
 

  connect() {
    this.subscribers = []
    this.session = OT.initSession(this.apiKeyValue, this.sessionIdValue);
    this.session.connect(this.tokenValue, this.handleCallback.bind(this))
    
    // Subscribe to a newly created stream
    var subscriberTarget = this.subscriberTarget
    this.session.on('streamCreated', this.streamCreated.bind(this) );
    
    console.log("this element", this.element)
    // we want to add a class to the subscriber element when the stream is destroyed
    this.session.on('streamDestroyed', this.unloadSelf.bind(this));
    
    console.log("session", this.session)
  }

  unloadSelf() {
    this.endSession()
    this.element.remove()
  }

  streamCreated(event) {  
    console.log("streamCreated")
    var subscriber = this.session.subscribe(event.stream, 'subscriber', {
      insertMode: 'append',
      width: '100%',
      height: '100%',
    }, this.handleCallback.bind(this))

    this.subscribers.push(subscriber)    
    this.cleanupExtraVideos(this.subscriberTarget)
    this.setVisibility(subscriber.id)
  }

  cleanupExtraVideos(subscriberTarget) {
    const subscriberIds = this.subscribers.map((subscriber) => subscriber.id)
    const vids = [...this.subscriberTarget.children]

    //remove any vids whose ids are not in the subscriberIds array
    vids.forEach((element) => {
      if (!subscriberIds.includes(element.id)) {
        subscriberTarget.removeChild(element)
      }
    })
  }

  setVisibility(id) {
    const vids = [...this.subscriberTarget.children]
  
    vids.find((element) => {
      if (element.id === id) {
        element.classList.remove('hidden');
      }
    })

    // hide the rest
    vids.forEach((element) => {
      if (element.id !== id) {
        element.classList.add('hidden');
      }
    })
  }

  endSession() {    
    this.subscriberTarget.innerHTML = ''
    this.session.disconnect()        
  }

  mute(event) {
    this.publisher.publishAudio(false);
  }

  unmute(event) {
    this.publisher.publishAudio(true);
  }


  handleCallback(error) {
    if (error) {
      alert(error.message);
      }
  }
}