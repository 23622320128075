import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static values = {
    url: String,
    exhibitorId: String,
    initialInterested: { type: Boolean, default: false }
  }

  connect() {
    if (this.hasInitialInterestedValue) {
      this.element.checked = this.initialInterestedValue
    }
  }

  async toggle(event) {
    const interested = event.target.checked

    const body = {
                  exhibitor_registration_id: this.exhibitorIdValue,
                  interested: interested
                }
    const request = new FetchRequest('post', this.urlValue, { responseKind: 'turbo-stream', body: body })

    try {
      const response = await request.perform()

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const elements = document.querySelectorAll(`[data-interest-exhibitor-id-value="${this.exhibitorIdValue}"]`)
      elements.forEach(element => {
        element.checked = interested
      })

      // Update the interests count in the header if needed
      const event = new CustomEvent('interestUpdated', {
        detail: { interested }
      })
      window.dispatchEvent(event)

    } catch (error) {
      console.error('Error:', error)
      event.target.checked = !interested
    }
  }
}

