import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'query', 'form'];
  static values = { minLength: Number, formId: String }

  connect() {
    const inputLength = this.queryTarget.value.length
    this.queryTarget.setSelectionRange(inputLength, inputLength)
    this.queryTarget.focus()
  }

  inputHandler() {
    this.debounce(this.search.bind(this), 500);
  }

  search() {
    const form = this.hasFormTarget ? this.formTarget : document.getElementById(this.formIdValue)
    form.requestSubmit()
  }

  clear() {
    this.queryTarget.value = ""
    this.queryTarget.focus()
    this.formTarget.requestSubmit()
    this.hideElement(this.clearButtonTarget)
  }


  debounce(callback, time) {
    clearTimeout(this.debounceTimerValue);
    this.debounceTimerValue = setTimeout(callback, time);
  }
}