import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = ['tab', 'panel']
  static values = { eventId: String }
  static classes = [ "active", "blinking" ]

  #loadingPanel = null

  connect() {
    this.boundHandleBlinkNotification = this.handleBlinkNotification.bind(this)
    document.addEventListener('blink-notification', this.boundHandleBlinkNotification)

    this.boundHandleCloseBoothNotification = this.handleCloseBoothNotification.bind(this)
    document.addEventListener('close-booth-notification', this.boundHandleCloseBoothNotification)
    
    // if there is a booth id in the URL, activate that room
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('booth_id')) {
      const boothId = urlParams.get('booth_id')
      this.#activatePanel(boothId)      
    }
  } 
  
  disconnect() {
    document.removeEventListener('blink-notification', this.boundHandleBlinkNotification)
    document.removeEventListener('close-booth-notification', this.boundHandleCloseBoothTab)
  }
  
  handleBlinkNotification(event) {
    const { mainRoomId } = event.detail
    
    if (mainRoomId) {
      const tab = this.tabTargets.find(tab => tab.dataset.mainRoomId == mainRoomId)
      if (tab) {
        const isActiveTab = tab.classList.contains(this.activeClasses[0])
        if (!isActiveTab) {
          tab.classList.add(...this.blinkingClasses)
        }
      }
    }
  }

  handleCloseBoothNotification(event) {
    const { boothId } = event.detail
    if (boothId) {
      const tab = this.#tabForBooth(boothId)
      if (tab) {
        this.#closeBoothTab(tab)
      }
    }
  }

  panelTargetConnected(element) {
    if (this.panelTargets.length === this.tabTargets.length) {
      if(this.#loadingPanel != null) {
        this.#activatePanel(this.#loadingPanel)
        this.#loadingPanel = null 
      } else {
        this.#selectInitialTab()
      }
    }
  }

  tabTargetConnected(element) {
    this.#fetchPanel(element)
    this.#activatePanel(element.dataset.id)
  }

  handleBoothTabClick(event) {
    const boothId = event.target.dataset.id
    if (event.target.classList.contains(this.blinkingClasses[0])) {
      const panel = this.#panelForBooth(boothId)
      const tab = panel.querySelector('.animate-blink-primary') 
      if (tab) {
        tab.click()
      }      
    }
    
    this.#activatePanel(boothId)
  }

  handleBoothTabCloseClick(event) {
    let boothTab = event.target.closest('tab');
    this.#closeBoothTab(boothTab)
    event.preventDefault();
  }

  #closeBoothTab(boothTab) {
    let boothPanel = this.#panelForBooth(boothTab.dataset.id);
    let nearestBoothTab = boothTab?.nextElementSibling || boothTab?.previousElementSibling;

    boothPanel.remove();
    boothTab.remove();
    
    this.#activatePanel(nearestBoothTab.dataset.id);
  }

  #selectInitialTab() {
    //put something here o select the tab if a new chat comes in maybe? hrmm.
    const tab = this.#tabForUrlParam()
    if (tab) {
      this.#activatePanel(tab.dataset.id)
    } else {
      this.#activatePanel('lobby')
    }
  }
  
  #tabForUrlParam() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('booth_id')) {
      const boothId = urlParams.get('booth_id')
      return this.#tabForBooth(boothId)
    }
  }

  #fetchPanel(node) {
    const boothId = node.dataset.id
    if (this.#panelForBooth(boothId)) { return } // if the panel is already here, nope out

    const url = `/candidate/events/${this.eventIdValue}/load_booth_panel/${boothId}?loading_type=append`
    const request = new FetchRequest('GET', url, { responseKind: 'turbo-stream' })
    request.perform()
    this.#loadingPanel = boothId
  }

  #activatePanel(boothId) {
    const tab = this.#tabForBooth(boothId)
    const panel = this.#panelForBooth(boothId)
    this.#hideAll()
    tab.classList.add(...this.activeClasses)
    tab.classList.remove(this.blinkingClasses)
    if (panel) panel.classList.remove('hidden')
  }

  #hideAll() {
    this.panelTargets.forEach((panel) => {panel.classList.add('hidden')})
    this.tabTargets.forEach((tab) => {tab.classList.remove(...this.activeClasses)})
  }

  #panelForBooth(boothId) {
    return this.panelTargets.find(panel => panel.dataset.id == boothId)
  }

  #tabForBooth(boothId) {
    return this.tabTargets.find(tab => tab.dataset.id == boothId)
  }


}