import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = ['messages', 'form', 'messageInput', 'unreadCount', 'muteButton', 'unmuteButton', 'message']
  static values = { currentUserId: String, muted: Boolean, unreadCount: Number, meetingId: String, rateLimitCount: Number, rateLimitSeconds: Number, causeBlink: Boolean, mainRoomId: String }

  connect() {
    this.messageCount = 0;
    this.notificationSound = new Audio('https://assets.recruitmilitary.com/mp3/new-message.mp3');
    
    this.messagesTarget.parentNode.scrollTop = this.messagesTarget.parentNode.scrollHeight
    this.observeMessagesVisibility();
  }
  
  disconnect() {
    // Cleanup audio resources if needed
    if (this.notificationSound) {
      this.notificationSound.pause();
      this.notificationSound.currentTime = 0;
    }
  }

  observeMessagesVisibility() {
    const options = {threshold: 0}
    const callback = (entries, observer) => {
      entries.forEach((entry) => {
        this.messagesTarget.parentNode.scrollTop = this.messagesTarget.parentNode.scrollHeight
        this.resetUnreadCount()
      });
    };
    
    const observer = new IntersectionObserver(callback, options)
    observer.observe(this.messagesTarget)
  }

  messageTargetConnected(element) {
    if (element.dataset.historical == "true") { return }

    if (element.dataset.userId === this.currentUserIdValue) {
      element.querySelector( "[data-poster='self']" ).classList.remove('hidden');
      element.querySelector( "[data-poster='other']" ).remove();
    } else if (element.dataset.userId !== this.currentUserIdValue) {
      element.querySelector( "[data-poster='other']" ).classList.remove('hidden');
      element.querySelector( "[data-poster='self']" ).remove();
      this.audioNotification();
      this.maybeIncrementUnreadCount();
      this.maybeDispatchBlinkEvent();
    }
    this.messagesTarget.parentNode.scrollTop = this.messagesTarget.parentNode.scrollHeight
  }

  startCall(element){
    const trigger = new CustomEvent("start-call");
    window.dispatchEvent(trigger);
  }

  maybeIncrementUnreadCount() {
        if (!this.isVisible()) {
      this.unreadCountValue += 1;
      this.unreadCountTarget.innerHTML = this.unreadCountValue;
      document.querySelectorAll(`[data-unread-count-value='${this.meetingIdValue}']`).forEach(node => { node.innerHTML = this.unreadCountValue });
      document.querySelectorAll(`[data-unread-count-value='${this.meetingIdValue}']`).forEach(node => { node.classList.remove('hidden') });
    }
  }

  resetUnreadCount() {
    this.unreadCountValue = 0;
    setTimeout(() => {
      this.unreadCountTarget.innerHTML = this.unreadCountValue
      document.querySelectorAll(`[data-unread-count-value='${this.meetingIdValue}']`).forEach(node => { node.innerHTML = this.unreadCountValue })
      document.querySelectorAll(`[data-unread-count-value='${this.meetingIdValue}']`).forEach(node => { node.classList.add('hidden') })
    }, 1000)
  }

  toggleMute() {
    this.mutedValue = !this.mutedValue
    if (this.mutedValue) {
      this.muteButtonTarget.classList.add('hidden');
      this.unmuteButtonTarget.classList.remove('hidden');
    } else {
      this.muteButtonTarget.classList.remove('hidden');
      this.unmuteButtonTarget.classList.add('hidden');
    }
  }

  audioNotification() {
    if (this.mutedValue) return;    
    this.notificationSound.play()
  }

  // if the chat component was initiated with blinking enabled, dispatch a custom event
  maybeDispatchBlinkEvent() {
    if (!this.causeBlinkValue) return;
    this.dispatch('blink-notification', {detail: {meetingId: this.meetingIdValue, mainRoomId: this.mainRoomIdValue}, bubbles: true, prefix: false})    
  }

  async submit(event) {
    event.preventDefault();
    // return early is the message is blank
    if (this.messageInputTarget.value.trim() === "") return;
    
    if(this.messageCount >= this.rateLimitCountValue) {
      alert('You are sending messages too quickly. Please wait.');
      return;
    }

    this.messageCount++;
    setTimeout(() => {
      this.messageCount = Math.max(0, this.messageCount - 1);
    }, this.rateLimitSecondsValue * 1000);

    this.sendToServer();
  }

  async sendToServer() {
    const form = event.target.closest('form')
    const formData = new FormData(form)
    const request = new FetchRequest('post', form.action, { responseKind: 'turbo-stream', body: formData })
    this.messageInputTarget.value = "";
    this.messageInputTarget.form.querySelectorAll('input').forEach(input => input.value = "")
    return await request.perform()
  }

  isVisible() {
    const rect = this.messagesTarget.getBoundingClientRect();
    return (
      // dimensions
      rect.width > 0 &&
      rect.height > 0 &&
      // in viewport
      rect.top < (window.innerHeight || document.documentElement.clientHeight) &&
      rect.left < (window.innerWidth || document.documentElement.clientWidth) &&
      rect.bottom > 0 &&
      rect.right > 0 &&
      // not hidden by CSS
      window.getComputedStyle(this.messagesTarget).display !== 'none' &&
      window.getComputedStyle(this.messagesTarget).visibility !== 'hidden'
    );
  }


}